import * as React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { GamePage, SplashPage, ResultPage, HighscorePage } from "./pages/index";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "./store/reducer";
import { Theme } from "./store/user/userReducer";
import { BackgroundColors__Transparent } from "./definitions/UserConstants";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ToastContainer, Zoom } from "react-toastify";
import Menu from "./components/Menu/Menu";
import SearchModal from "./components/Modals/SearchModal/SearchModal";
import NewGameModal from "./components/Modals/NewGameModal/NewGameModal";
import MultiplayerHostModal from "./components/Modals/MultiplayerHostModal/MultiplayerHostModal";
import MultiplayerClientModal from "./components/Modals/MultiplayerClientModal/MultiplayerClientModal";

export const App = () => {
  const state = useSelector((state: RootStateType) => state);
  const {
    user: { theme },
  } = state;
  const location = useLocation();

  return (
    <div
      id='App'
      style={{
        //@ts-ignore
        backgroundColor: BackgroundColors__Transparent[Theme[theme]],
      }}
    >
      <AnimatePresence exitBeforeEnter={false}>
        <Menu />
        <SearchModal key='searchModal' />
        <NewGameModal key='newgameModal' />
        <MultiplayerHostModal key='multiplayerHostModal' />
        <MultiplayerClientModal key='multiplayerClientModal' />
        <Switch location={location} key={location.pathname}>
          <Route path='/game'>
            <GamePage />
          </Route>
          <Route exact={true} path='/'>
            <SplashPage />
          </Route>
          <Route path='/result'>
            <ResultPage />
          </Route>
          <Route path='/highscores'>
            <HighscorePage />
          </Route>
        </Switch>
      </AnimatePresence>
      <ToastContainer transition={Zoom} />
    </div>
  );
};
