import { gameStateType } from "../store/game/gameReducer";
import { GameSummary } from "../definitions/HistoryInterfaces";
import { TileDefinition } from "../definitions/GameboardInterfaces";

export function convertGameStateToGameSummary(gameState: gameStateType) {
  let gameSummary: GameSummary = {
    Points: gameState.points,
    EncodedTiles: encodeTiles(gameState.visibleTiles),
    Start: gameState.gameInfos?.Start,
    End: gameState.gameInfos?.End,
  };
  return gameSummary;
}

export function encodeTiles(tiles: Array<TileDefinition>): string {
  let encodedTiles: Array<string> = tiles.map((currentTile, currentIndex) => {
    return (
      currentTile.type + "p" + currentTile.tilePosition + "o" + currentIndex
    );
  });
  let encodedString = encodedTiles.join("_");
  return encodedString;
}

export function decodeTiles(encodedString: string) {
  let encodedTiles = encodedString.split("_");
  let decodedTiles = encodedTiles.map((tile) => {
    let [tiletype, position, index] = tile.split(/[op]/);
    return {
      type: tiletype,
      tilePosition: Number(position),
      index: Number(index),
    };
  });
  decodedTiles = decodedTiles.sort((a, b) => a.index - b.index);
  return decodedTiles;
}
