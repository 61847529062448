import { Theme } from "./userReducer";
import { uuidv4 } from "../../helper/UserHelper";
import { ModalTypes } from "../../definitions/UserConstants";

export const SET_THEME = "SET_THEME";
export const SET_NAME = "SET_NAME";
export const SET_MODAL = "SET_MODAL";
export const SET_ID = "SET_ID";

interface setThemeAction {
  type: typeof SET_THEME;
  payload: Theme;
}
interface setNameAction {
  type: typeof SET_NAME;
  payload: string;
}
interface setModalAction {
  type: typeof SET_MODAL;
  payload: ModalTypes;
}
interface setIdAction {
  type: typeof SET_ID;
  payload: string;
}

export function setTheme(theme: Theme): setThemeAction {
  return {
    type: SET_THEME,
    payload: theme,
  };
}
export function setName(name: string): setNameAction {
  return {
    type: SET_NAME,
    payload: name,
  };
}

export function setModal(visibleModal = ModalTypes.None): setModalAction {
  return {
    type: SET_MODAL,
    payload: visibleModal,
  };
}

export function setId(id = uuidv4()): setIdAction {
  return {
    type: SET_ID,
    payload: id,
  };
}

export type ActionTypes =
  | setThemeAction
  | setNameAction
  | setModalAction
  | setIdAction;
