import { ActionTypes, ADD_GAME_SUMMARY } from "./historyActions";
import { GameSummary } from "../../definitions/HistoryInterfaces";

export interface historyStateType {
  gameSummaries: Array<GameSummary>;
}

const initialState: historyStateType = {
  gameSummaries: [],
};

export function historyReducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case ADD_GAME_SUMMARY:
      return {
        ...state,
        gameSummaries: [...state.gameSummaries, action.payload],
      };
    default:
      return state;
  }
}
