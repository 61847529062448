import "./index.css";
import * as React from "react";
import { GameSummary } from "../../definitions/HistoryInterfaces";
import { GameTypes } from "../../definitions/GameboardInterfaces";
import { getHighscoreDateString } from "../../helper/HighscoresHelper";

export interface HighscoreTileProps {
  summary: GameSummary;
  style?: React.CSSProperties;
}

export const HighscoreTile: React.FunctionComponent<HighscoreTileProps> = (
  props
) => {
  const { summary } = props;
  return (
    <div
      className={["highscoretile"].join(" ")}
      style={{
        ...props.style,
        backgroundImage:
          "url('/static/highscores/tile_" +
          getColorForPoints(summary.Points) +
          ".svg')",
      }}
    >
      <img
        className="highscoretile__icon"
        src={
          "/static/gametypes/" +
          GameTypes[summary.GameOptions?.type || 0].toLowerCase() +
          ".svg"
        }
        alt={GameTypes[summary.GameOptions?.type || 0]}
      />
      <div className="highscoretile__points">{summary.Points}</div>
      <div className="highscoretile__date">
        {getHighscoreDateString(
          //@ts-ignore
          new Date(summary.Start) || new Date("1960-01-01")
        )}
      </div>
    </div>
  );
};

export default HighscoreTile;

function getColorForPoints(points: number) {
  if (points > 200) return "blue";
  if (points > 150) return "turquoise";
  if (points > 100) return "yellow";
  return "pink";
}
