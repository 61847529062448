export interface TileDefinition {
  tilePosition: number;
  type: string;
  locked: boolean;
}

export interface TilePosition {
  x: number;
  y: number;
}

export interface ValidTilePosition {
  coordinates: TilePosition;
  usedById: string | null;
}

export interface GameOptions {
  type: GameTypes;
  selectionSeed?: number;
  orderSeed?: string;
}
export interface GameInfos {
  Start?: Date;
  End?: Date;
}

export enum GameTypes {
  Random,
  Select,
  MultiplayerHost,
  MultiplayerClient,
}

export enum GameStates {
  InProgress,
  Finished,
  ViewBoard,
}

export enum GameBoardTypes {
  Desktop,
  MobilePortrait,
  MobileLandscape,
  TabletPortrait,
  TabletLandscape,
}
