import * as React from "react";
import "./index.css";
import { Redirect, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { setName } from "../../store/user/userActions";
import { motion } from "framer-motion";
import Button from "../../components/Buttons/Button/Button";
import HighscoreTile from "../../components/HighscoreTile/HighscoreTile";

export const HighscorePage = () => {
  const gameSummaries = useSelector(
    (state: RootStateType) => state.history.gameSummaries
  );

  const pageTransition = {
    in: { opacity: 1 },
    out: { opacity: 0 },
  };

  return (
    <motion.div
      className='highscores'
      initial='out'
      animate='in'
      exit='out'
      variants={pageTransition}
      transition={{ duration: 1 }}
    >
      <NavLink to='/game' className='highscores__back'>
        <Button
          className='highscores__back'
          content='back'
          onClick={() => {}}
        />
      </NavLink>
      <h1 className='highscores__title'>
        Deine
        <br />
        Highscores
      </h1>
      <section
        className='highscores__list'
        style={{
          height:
            (Math.floor((gameSummaries.length - 1) / 3) * 26 + 46).toString() +
            "vw",
        }}
      >
        {gameSummaries
          .sort((a, b) => {
            return (
              //@ts-ignore
              new Date(b.Start || "1960-01-01") -
              //@ts-ignore
              new Date(a.Start || "1960-01-01")
            );
          })
          .map((summary, index) => {
            return (
              <HighscoreTile
                key={(summary.Start?.toString() || "") + summary.EncodedTiles}
                summary={summary}
                style={summaryIndexToPosition(index)}
              />
            );
          })}
      </section>
    </motion.div>
  );
};

function summaryIndexToPosition(index: number): React.CSSProperties {
  if (index === 0) {
    return { position: "absolute", left: "37vw", top: "0vw" };
  }
  if (index % 3 === 1) {
    return {
      position: "absolute",
      left: "14vw",
      top: (Math.floor(index / 3) * 26 + 13).toString() + "vw",
    };
  }
  if (index % 3 === 2) {
    return {
      position: "absolute",
      left: "37vw",
      top: (Math.floor(index / 3) * 26 + 26).toString() + "vw",
    };
  }
  if (index % 3 === 0) {
    return {
      position: "absolute",
      left: "60vw",
      top: (Math.floor(index / 3) * 26 - 13).toString() + "vw",
    };
  }
  return {};
}

export default HighscorePage;
