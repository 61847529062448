import "./index.css";
import * as React from "react";
import { slide as Slide, State } from "react-burger-menu";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { BackgroundColors, ModalTypes } from "../../definitions/UserConstants";
import { Theme } from "../../store/user/userReducer";
import { setModal, setTheme } from "../../store/user/userActions";
import ColorButton from "../Buttons/ColorButton/ColorButton";
import MenuButton from "../Buttons/MenuButton/MenuButton";
import { NavLink } from "react-router-dom";

export interface MenuProps {
  className?: string;
}

export const Menu: React.FunctionComponent<MenuProps> = (props) => {
  function showSettings(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    event.preventDefault();
  }

  const currentModal = useSelector(
    (state: RootStateType) => state.user.currentModal
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  const dispatch = useDispatch();

  var styles = {
    bmMenu: {
      //@ts-ignore
      background: BackgroundColors[Theme[theme]],
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmOverlay: {
      backdropFilter: "blur(5px)",
    },
  };

  function menuStateChanged(state: State) {
    if (!state.isOpen && currentModal !== ModalTypes.Menu) {
      return;
    }
    let openModal = ModalTypes.Menu;
    if (!state.isOpen && currentModal === ModalTypes.Menu) {
      openModal = ModalTypes.None;
    }
    dispatch(setModal(openModal));
  }

  const handleResetGame = () => {
    dispatch(setModal(ModalTypes.NewGame));
  };

  return (
    <Slide
      isOpen={currentModal === ModalTypes.Menu}
      className="menu__content"
      customBurgerIcon={false}
      customCrossIcon={false}
      styles={styles}
      onStateChange={menuStateChanged}
    >
      <MenuButton
        className="menu__reset"
        icon="reset"
        onClick={() => {
          handleResetGame();
        }}
      >
        Neues Spiel
      </MenuButton>
      <NavLink to="/highscores" className="menu__link">
        <MenuButton
          className="menu__highscores"
          icon="highscores"
          onClick={() => {
            dispatch(setModal());
          }}
        >
          Highscores
        </MenuButton>
      </NavLink>
      <ColorButton
        className="menu__theme"
        onChange={(color: Theme) => {
          dispatch(setTheme(color));
        }}
      />
      <div className="packageversion">
        Version {process.env.PACKAGE_VERSION}
      </div>
    </Slide>
  );
};

export default Menu;
