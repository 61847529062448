import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import {
  ADD_GAME_SUMMARY,
  addGameSummaryAction,
  addGameSummary,
  ADD_GAME_SUMMARY_REMOTE,
  addGameSummaryRemote,
} from "./historyActions";
import { PersonalizedGameSummary } from "../../definitions/HistoryInterfaces";
import { RootStateType } from "../reducer";
import { UPDATE_GAME_STATE, updateGameStateAction } from "../game/gameActions";
import { GameStates } from "../../definitions/GameboardInterfaces";
import { convertGameStateToGameSummary } from "../../helper/HistoryHelper";
import { format } from "path";
import { uuidv4 } from "../../helper/UserHelper";
import { setId } from "../user/userActions";

function* addGameSummarySaga(action: addGameSummaryAction) {
  const { name, id } = yield select((state: RootStateType) => state.user);
  let newId = id;
  if (newId == null || newId === "") {
    newId = uuidv4();
    yield put(setId(newId));
  }
  const personalizedGameSummary: PersonalizedGameSummary = {
    UserName: name,
    UserId: id,
    Summary: action.payload,
  };
  yield put(addGameSummaryRemote(personalizedGameSummary));
}

function* historySaga() {
  yield takeLatest(ADD_GAME_SUMMARY, addGameSummarySaga);
}

export default historySaga;
