import "./index.css";
import * as React from "react";
import Button from "../Button/Button";
import InputButton from "../InputButton/InputButton";

export interface ButtonProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  className?: string;
}

export const SearchButton: React.FunctionComponent<ButtonProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      className={[
        props.className,
        "searchbutton",
        expanded ? "expanded" : "",
      ].join(" ")}
    >
      <Button
        content='search'
        onClick={() => {
          setExpanded(!expanded);
        }}
      />
      <InputButton
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
      />
    </div>
  );
};

export default SearchButton;
