import {
  TileDefinition,
  TilePosition,
  GameStates,
  GameOptions,
  GameTypes,
  GameInfos,
} from "../../definitions/GameboardInterfaces";

export const ADD_TILE = "ADD_TILE";
export const SET_GAME_INFOS = "SET_GAME_INFO";
export const MOVE_TILE = "MOVE_TILE";
export const MOVE_TILE_TO_INDEX = "MOVE_TILE_TO_INDEX";
export const UPDATE_GAME_STATE = "SET_GAME_STATE";
export const RESET_GAME = "RESET_GAME";
export const UPDATE_POINTS = "UPDATE_POINTS";
export const VIEW_GAMEBOARD = "VIEW_GAMEBOARD";
export const VIEW_GAMEBOARD_REDUCER = "VIEW_GAMEBOARD_REDUCER";
export const SET_POSITION_PARAMETER = "SET_POSITION_PARAMETER";
export const SET_GAME_OPTIONS = "SET_GAME_OPTIONS";
export const UPDATE_VALID_POSITIONS = "UPDATE_VALID_POSITIONS";

export interface setPositionParameterAction {
  type: typeof SET_POSITION_PARAMETER;
  payload: { multiplier: TilePosition; offset: TilePosition };
}
export interface moveTileAction {
  type: typeof MOVE_TILE;
  payload: { tile: TileDefinition; position: TilePosition };
}
export interface moveTileToIndexAction {
  type: typeof MOVE_TILE_TO_INDEX;
  payload: { tile: TileDefinition; tilePosition: number };
}
export interface updateGameStateAction {
  type: typeof UPDATE_GAME_STATE;
  payload: GameStates;
}
export interface viewGameboardAction {
  type: typeof VIEW_GAMEBOARD;
}
export interface viewGameboardRecuderAction {
  type: typeof VIEW_GAMEBOARD_REDUCER;
}
export interface updateValidPositionsAction {
  type: typeof UPDATE_VALID_POSITIONS;
  payload: Boolean;
}
export interface resetGameAction {
  type: typeof RESET_GAME;
  payload: GameOptions;
}
export interface addTileAction {
  type: typeof ADD_TILE;
  payload: TileDefinition;
}
export interface updatePointsAction {
  type: typeof UPDATE_POINTS;
}
export interface setGameOptionsAction {
  type: typeof SET_GAME_OPTIONS;
  payload: GameOptions;
}
export interface setGameInfosAction {
  type: typeof SET_GAME_INFOS;
  payload: GameInfos;
}

export function setPositionParameter(parameter: {
  multiplier: TilePosition;
  offset: TilePosition;
}): setPositionParameterAction {
  return {
    type: SET_POSITION_PARAMETER,
    payload: parameter,
  };
}

export function moveTile(
  tile: TileDefinition,
  position: TilePosition
): moveTileAction {
  return {
    type: MOVE_TILE,
    payload: { tile, position },
  };
}
export function moveTileToIndex(
  tile: TileDefinition,
  tilePosition: number
): moveTileToIndexAction {
  return {
    type: MOVE_TILE_TO_INDEX,
    payload: { tile, tilePosition },
  };
}
export function resetGame(
  options: GameOptions = { type: GameTypes.Random }
): resetGameAction {
  return {
    type: RESET_GAME,
    payload: options,
  };
}
export function viewGameboard(): viewGameboardAction {
  return {
    type: VIEW_GAMEBOARD,
  };
}
export function viewGameboardRecucer(): viewGameboardRecuderAction {
  return {
    type: VIEW_GAMEBOARD_REDUCER,
  };
}

export function updateValidPositions(
  useOnlyLockedTiles = true
): updateValidPositionsAction {
  return {
    type: UPDATE_VALID_POSITIONS,
    payload: useOnlyLockedTiles,
  };
}
export function updateGameState(newState: GameStates): updateGameStateAction {
  return {
    type: UPDATE_GAME_STATE,
    payload: newState,
  };
}
export function addTile(tile: TileDefinition): addTileAction {
  return {
    type: ADD_TILE,
    payload: tile,
  };
}
export function setGameInfos(gameInfos: GameInfos): setGameInfosAction {
  return {
    type: SET_GAME_INFOS,
    payload: gameInfos,
  };
}
export function updatePoints(): updatePointsAction {
  return {
    type: UPDATE_POINTS,
  };
}

export function setGameOptions(options: GameOptions): setGameOptionsAction {
  return {
    type: SET_GAME_OPTIONS,
    payload: options,
  };
}

export type ActionTypes =
  | moveTileAction
  | addTileAction
  | setPositionParameterAction
  | updateGameStateAction
  | resetGameAction
  | viewGameboardAction
  | viewGameboardRecuderAction
  | moveTileToIndexAction
  | updateValidPositionsAction
  | setGameOptionsAction
  | updatePointsAction
  | setGameInfosAction;
