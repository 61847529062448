import "./index.css";
import * as React from "react";

export interface Seperator {
  color: string;
}

export const Seperator: React.FunctionComponent<Seperator> = (props) => {
  const { color } = props;
  return (
    <div
      className="seperator"
      style={{
        fontSize: "3rem",
        backgroundImage: `url(/static/seperator/${color}.svg)`,
      }}
    />
  );
};

export default Seperator;
