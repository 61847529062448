import "./index.css";
import * as React from "react";

export interface MenuButtonProps {
  icon: string;
  onClick: () => void;
  className?: string;
}

export const MenuButton: React.FunctionComponent<MenuButtonProps> = (props) => {
  const { icon, onClick, className } = props;
  return (
    <div
      className={[className, "menubutton"].join(" ")}
      onClick={() => {
        onClick();
      }}
    >
      <img
        className="menubutton__icon"
        src={`/static/buttons/${icon}.svg`}
        alt=""
      />
      <span className="menubutton__text">{props.children}</span>
    </div>
  );
};

export default MenuButton;
