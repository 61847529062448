import * as React from "react";
import "./index.css";
import { Redirect, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { motion } from "framer-motion";
import { getResult } from "../../helper/GameboardHelper";
import { resetGame, viewGameboard } from "../../store/game/gameActions";
import { GameStates } from "../../definitions/GameboardInterfaces";
import { setModal } from "../../store/user/userActions";
import { ModalTypes } from "../../definitions/UserConstants";
import ActionButton from "../../components/Buttons/ActionButton/ActionButton";

export const ResultPage = () => {
  const visibleTiles = useSelector(
    (state: RootStateType) => state.game.visibleTiles
  );
  const name = useSelector((state: RootStateType) => state.user.name);
  const game = useSelector((state: RootStateType) => state.game);
  const { points, gameState } = game;
  const dispatch = useDispatch();

  const renderResultMessage = () => {
    return (
      <div className="message">
        <div className="message__label">{points} Punkte</div>
        <div className="message__sublabel">Gut gemacht {name}!</div>
        <div className="message__actions">
          <NavLink to="/game" className="action__back">
            <ActionButton
              className="action__back"
              onClick={() => {
                dispatch(viewGameboard());
              }}
            >
              {"<"} Spielfeld
            </ActionButton>
          </NavLink>
          <NavLink to="/game" className="action__back">
            <ActionButton
              className="action__new"
              onClick={() => {
                dispatch(setModal(ModalTypes.NewGame));
              }}
            >
              Nochmal {">"}
            </ActionButton>
          </NavLink>
        </div>
      </div>
    );
  };

  const pageTransition = {
    in: { opacity: 1 },
    out: { opacity: 0 },
  };

  const renderPage = () => {
    return (
      <div className="result_blur">
        <img
          className={["result__logoimage"].join(" ")}
          src={"/static/Logo.svg"}
        ></img>
        {renderResultMessage()}
      </div>
    );
  };
  return (
    <motion.div
      className="result"
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      transition={{ duration: 1 }}
    >
      {renderPage()}
    </motion.div>
  );
};

export default ResultPage;
