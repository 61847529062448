import * as React from "react";
import Tile from "./Tile";
import { TileDefinition } from "../../definitions/GameboardInterfaces";

const styles = {
  display: "inline-block",
  overflow: "hidden",
};

export interface BoxDragPreviewProps {
  definition: TileDefinition;
}

export interface BoxDragPreviewState {
  tickTock: any;
}

const BoxDragPreview: React.FC<BoxDragPreviewProps> = ({ definition }) => {
  if (definition) {
    return (
      <div style={styles}>
        <Tile className='tile--dragged' definition={definition} />
      </div>
    );
  } else {
    return <div style={styles}></div>;
  }
};

export default BoxDragPreview;
