import "./index.css";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { Theme } from "../../store/user/userReducer";
import { TileDefinition } from "../../definitions/GameboardInterfaces";
import { getCoordinates } from "../../helper/GameboardHelper";
import TileSvg from "./TileSvg";
import { ForegroundColors } from "../../definitions/UserConstants";

export interface TileProps {
  definition: TileDefinition;
  className?: string;
  style?: any;
}

export const Tile: React.FunctionComponent<TileProps> = (props) => {
  const validPositions = useSelector(
    (state: RootStateType) => state.game.validPositions
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  // @ts-ignore
  const fillColor = ForegroundColors[Theme[theme]];

  if (props.definition && !props.definition.locked) {
    return (
      <div className={[props.className, "tile"].join(" ")} style={props.style}>
        <TileSvg type={props.definition.type} fillColor={fillColor} />
      </div>
    );
  } else {
    let coordinates = getCoordinates(props.definition, validPositions);

    return (
      <div
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        draggable={false}
        className={[props.className, "tile", "tile--locked"].join(" ")}
        style={{
          top: coordinates.y,
          left: coordinates.x,
        }}
      >
        <TileSvg type={props.definition.type} fillColor={fillColor} />
      </div>
    );
  }
};

export default Tile;
