import * as React from "react";
import "./index.css";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { setName } from "../../store/user/userActions";
import { motion } from "framer-motion";

export const SplashPage = () => {
  const name = useSelector((state: RootStateType) => state.user.name);
  const dispatch = useDispatch();
  let [showSplash, setShowSplash] = React.useState(true);
  let [nameInput, setNameInput] = React.useState("");

  if (name != null && name !== "") {
    setTimeout(() => {
      setShowSplash(false);
    }, 2000);
  }

  const handleNameConfirm = () => {
    dispatch(setName(nameInput));
    setShowSplash(false);
  };

  const renderEnterName = () => {
    return (
      <div className="name">
        <div className="name__label">Wie heisst du?</div>
        <input
          className="name__input"
          type="text"
          onChange={(event) => {
            setNameInput(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleNameConfirm();
            }
          }}
        />
        <button
          className="name__confirm"
          onClick={() => {
            handleNameConfirm();
          }}
        >
          Spielen
        </button>
      </div>
    );
  };
  const renderWelcome = () => {
    return (
      <div className="name">
        <div className="name__label">Willkommen zurück {name}!</div>
        <div className="name__sublabel">Viel Spass beim Spielen</div>
      </div>
    );
  };

  const pageTransition = {
    in: { opacity: 1 },
    out: { opacity: 0 },
  };

  const renderRedirect = () => {
    return <Redirect push to={{ pathname: "/game" }} />;
  };
  const renderPage = () => {
    return (
      <div className="splash_blur">
        <img
          className={["splash__logoimage"].join(" ")}
          src={"/static/Logo.svg"}
        />
        {name == null || name == "" ? renderEnterName() : renderWelcome()}
      </div>
    );
  };

  return (
    <motion.div
      className="splash"
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      transition={{ duration: 1 }}
    >
      {renderPage()}
      {showSplash ? "" : renderRedirect()}
    </motion.div>
  );
};

export default SplashPage;
