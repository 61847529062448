import { createStore, combineReducers, applyMiddleware } from "redux";
import { gameReducer, gameStateType } from "./game/gameReducer";
import { userReducer, userStateType } from "./user/userReducer";
import { historyReducer, historyStateType } from "./history/historyReducer";

export type StateTypes = userStateType | gameStateType;

export const rootReducer = combineReducers({
  game: gameReducer,
  history: historyReducer,
  user: userReducer,
});

export interface RootStateType {
  game: gameStateType;
  history: historyStateType;
  user: userStateType;
}
