import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import TileDropzone from "../TileDropzone/TileDropzone";
import { calculatePositionParameter } from "../../helper/GameboardHelper";
import { setPositionParameter } from "../../store/game/gameActions";
import CustomDragLayer from "../Tile/CustomDragBox";
import Tile from "../Tile/Tile";
import DraggableTile from "../Tile/DraggableTile";
import { addGameSummary } from "../../store/history/historyActions";

export interface GameboardProps {
  className?: string;
}

export const Gameboard: React.FunctionComponent<GameboardProps> = (props) => {
  const validPositions = useSelector(
    (state: RootStateType) => state.game.validPositions
  );
  const visibleTiles = useSelector(
    (state: RootStateType) => state.game.visibleTiles
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      dispatch(setPositionParameter(calculatePositionParameter()));
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setTimeout(() => {
      dispatch(setPositionParameter(calculatePositionParameter()));
    }, 100);
  }, []);

  return (
    <div id='gameboard' className={[props.className, "gameboard"].join(" ")}>
      <CustomDragLayer snapToGrid={false} />
      {validPositions.map((position, index) => {
        return (
          <TileDropzone
            key={position.coordinates.x + position.coordinates.y}
            tilePosition={index}
            style={{
              position: "absolute",
              left: position.coordinates.x + "px",
              top: position.coordinates.y + "px",
            }}
          />
        );
      })}
      {visibleTiles.map((tile, index) => {
        if (tile.locked) {
          return <Tile key={tile.type} definition={{ ...tile }} />;
        } else {
          return (
            <DraggableTile
              key={tile.type}
              id={tile.type}
              definition={{ ...tile }}
            />
          );
        }
      })}
    </div>
  );
};

function debounce(func: Function, wait: number, immediate = false) {
  var timeout: NodeJS.Timeout | null;

  return function executedFunction() {
    //@ts-ignore
    var context: any = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default Gameboard;
