import {
  ActionTypes,
  SET_THEME,
  SET_NAME,
  SET_MODAL,
  SET_ID,
} from "./userActions";
import { ModalTypes } from "../../definitions/UserConstants";

export enum Theme {
  Yellow,
  Red,
  Purple,
  Blue,
  Green,
  Grey,
}

export interface userStateType {
  id: string | null;
  name: string | null;
  theme: Theme;
  currentModal: ModalTypes;
}

const initialState: userStateType = {
  id: null,
  name: "",
  theme: Theme.Blue,
  currentModal: ModalTypes.None,
};

export function userReducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_MODAL:
      return {
        ...state,
        currentModal: action.payload,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };

    default:
      return state;
  }
}
