import {
  GameSummary,
  PersonalizedGameSummary,
} from "../../definitions/HistoryInterfaces";
import { OfflineMetadata } from "@redux-offline/redux-offline/lib/types";

export const ADD_GAME_SUMMARY = "ADD_GAME_SUMMARY";
export const ADD_GAME_SUMMARY_REMOTE = "ADD_GAME_SUMMARY_REMOTE";
export const ADD_GAME_SUMMARY_REMOTE__SUCCESS =
  "ADD_GAME_SUMMARY_REMOTE__SUCCESS";
export const ADD_GAME_SUMMARY_REMOTE__FAILED =
  "ADD_GAME_SUMMARY_REMOTE__FAILED";

export interface addGameSummaryAction {
  type: typeof ADD_GAME_SUMMARY;
  payload: GameSummary;
}

export interface addGameSummaryRemoteAction {
  type: typeof ADD_GAME_SUMMARY_REMOTE;
  payload: PersonalizedGameSummary;
  meta: { offline: OfflineMetadata };
}

export function addGameSummary(summary: GameSummary): addGameSummaryAction {
  return {
    type: ADD_GAME_SUMMARY,
    payload: summary,
  };
}

export function addGameSummaryRemote(
  summary: PersonalizedGameSummary
): addGameSummaryRemoteAction {
  return {
    type: ADD_GAME_SUMMARY_REMOTE,
    payload: summary,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: "/.netlify/functions/saveGame",
          method: "POST",
          json: summary,
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: ADD_GAME_SUMMARY_REMOTE__SUCCESS,
          meta: { completed: true, success: true },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: ADD_GAME_SUMMARY_REMOTE__SUCCESS,
          meta: { completed: true, success: false },
        },
      },
    },
  };
}

export type ActionTypes = addGameSummaryAction | addGameSummaryRemoteAction;
