import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import {
  ActionTypes,
  RESET_GAME,
  updateGameState,
  VIEW_GAMEBOARD,
  viewGameboardRecucer,
  SET_POSITION_PARAMETER,
  updateValidPositions,
  ADD_TILE,
  MOVE_TILE_TO_INDEX,
  updatePoints,
  MOVE_TILE,
  UPDATE_GAME_STATE,
  updateGameStateAction,
  setGameInfos,
} from "./gameActions";
import { GameStates } from "../../definitions/GameboardInterfaces";
import { RootStateType } from "../reducer";
import { getNewGameState } from "../../helper/GameboardHelper";
import { convertGameStateToGameSummary } from "../../helper/HistoryHelper";
import { addGameSummary } from "../history/historyActions";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* resetGame(action: ActionTypes) {
  yield put(updateGameState(GameStates.InProgress));
}

function* viewGameboard(action: ActionTypes) {
  yield put(viewGameboardRecucer());
  yield put(updateGameState(GameStates.ViewBoard));
}

function* updateValidPositionsLocked(action: ActionTypes) {
  yield put(updateValidPositions());
}
function* addTile(action: ActionTypes) {
  yield put(updateValidPositions(false));
}
function* moveTile(action: ActionTypes) {
  const gameState = yield select((state: RootStateType) => state.game);
  yield put(updatePoints());
  yield put(updateGameState(getNewGameState(gameState)));
}
function* updateGameStateSaga(action: updateGameStateAction) {
  switch (action.payload) {
    case GameStates.Finished:
      console.log(action);
      yield put(setGameInfos({ End: new Date() }));
      break;
    default:
      break;
  }
  yield put(updatePoints());

  // do stuff to send the gamesummary
  const gameState = yield select((state: RootStateType) => state.game);
  const newGameState = action.payload;
  if (newGameState === GameStates.Finished) {
    let gameSummary = convertGameStateToGameSummary({
      ...gameState,
      gameState: newGameState,
    });

    yield put(addGameSummary(gameSummary));
  }
}

function* gameSaga() {
  yield takeLatest(RESET_GAME, resetGame);
  yield takeLatest(VIEW_GAMEBOARD, viewGameboard);
  yield takeLatest(SET_POSITION_PARAMETER, updateValidPositionsLocked);
  yield takeLatest(ADD_TILE, addTile);
  yield takeLatest(MOVE_TILE, moveTile);
  yield takeLatest(UPDATE_GAME_STATE, updateGameStateSaga);
}

export default gameSaga;
