import "./index.css";
import * as React from "react";

export interface SearchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
}

export const SearchInput: React.FunctionComponent<SearchInputProps> = (
  props
) => {
  return (
    <div className='searchinput'>
      <input
        autoFocus={true}
        value={props.value}
        className='searchinput__input'
        type='number'
        name='searchinput'
        maxLength={3}
        onChange={props.onChange}
      />
    </div>
  );
};

export default SearchInput;
