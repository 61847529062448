import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../../store/reducer";
import {
  BackgroundColors,
  ModalTypes,
} from "../../../definitions/UserConstants";
import { Theme } from "../../../store/user/userReducer";
import { setModal } from "../../../store/user/userActions";
import EmojiInput from "../../EmojiInput/EmojiInput";
import Button from "../../Buttons/Button/Button";
import ActionButton from "../../Buttons/ActionButton/ActionButton";
import ReactModal from "react-modal";

export interface MultiplayerHostModalProps {
  className?: string;
}

export const MultiplayerHostModal: React.FunctionComponent<MultiplayerHostModalProps> = (
  props
) => {
  const currentModal = useSelector(
    (state: RootStateType) => state.user.currentModal
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  const dispatch = useDispatch();

  ReactModal.setAppElement("#root");

  function closeModal() {
    dispatch(setModal());
  }

  return (
    <ReactModal
      isOpen={currentModal === ModalTypes.MultiplayerHost}
      onRequestClose={closeModal}
      className="multiplayerhostmodal"
      overlayClassName="multiplayerhostmodal__overlay"
      style={{
        content:
          //@ts-ignore
          { backgroundColor: BackgroundColors[Theme[theme]] },
      }}
    >
      <img
        className="multiplayerhostmodal__logo"
        src="/static/buttons/multiplayerhost.svg"
        alt="multiplayer logo"
      />
      <div className="multiplayerhostmodal__text">
        Diese Emoji-Kette ist dein Schlüssel für ein Spiel mit Freunden...
      </div>
      <EmojiInput onChange={(value) => console.log(value)} readOnly={true} />
      <div className="multiplayerhostmodal__text">
        ...sie garantiert, dass ihr mit den gleichen Teilen in gleicher
        Reihenfolge spielt.
      </div>
      <ActionButton
        className="action__play"
        onClick={() => {
          dispatch(setModal(ModalTypes.None));
        }}
      >
        Spielen
      </ActionButton>
    </ReactModal>
  );
};

export default MultiplayerHostModal;
