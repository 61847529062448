export function getHighscoreDateString(date: Date) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let thisWeek = getMonday(today);
  let lastWeek = new Date();
  lastWeek.setDate(thisWeek.getDate() - 7);
  let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  let thisYear = new Date(today.getFullYear(), 1, 1);

  if (date >= today) return "Heute";
  if (date >= yesterday) return "Gestern";
  if (date >= thisWeek) return "Diese Woche";
  if (date >= lastWeek) return "Letze Woche";
  if (date >= thisMonth) return "Diesen Monat";
  if (date >= thisYear) return "Dieses Jahr";
  return "Älter";
}

function getMonday(d: Date) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
