import "./index.css";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTile, moveTile, resetGame } from "../../store/game/gameActions";
import { setTheme, setModal } from "../../store/user/userActions";
import { RootStateType } from "../../store/reducer";
import Button from "../Buttons/Button/Button";
import { SearchButton } from "../Buttons/SearchButton/SearchButton";
import ColorButton from "../Buttons/ColorButton/ColorButton";
import { Theme } from "../../store/user/userReducer";
import {
  TileDefinition,
  GameStates,
  GameTypes,
  GameBoardTypes,
} from "../../definitions/GameboardInterfaces";
import { INITIAL_TILE_POSITION } from "../../definitions/GameboardConstants";
import { toast } from "react-toastify";
import { getCurrentGameBoardType } from "../../helper/GameboardHelper";
import { ModalTypes } from "../../definitions/UserConstants";
import { NavLink } from "react-router-dom";

export interface ActionBarProps {
  className?: string;
}

export const ActionBar: React.FunctionComponent<ActionBarProps> = (props) => {
  const availableTileTypes = useSelector(
    (state: RootStateType) => state.game.availableTileTypes
  );
  const visibleTiles = useSelector(
    (state: RootStateType) => state.game.visibleTiles
  );
  const gameState = useSelector((state: RootStateType) => state.game.gameState);
  const gameOptions = useSelector(
    (state: RootStateType) => state.game.gameOptions
  );

  const dispatch = useDispatch();
  const [tileType, setTileType] = React.useState("");

  const handleOpenMenu = () => {
    dispatch(setModal(ModalTypes.Menu));
  };

  const handleAddTile = (selectedTiletype?: string) => {
    selectedTiletype = selectedTiletype || tileType;

    if (isAddingTileAllowed(selectedTiletype)) {
      const newTile: TileDefinition = {
        tilePosition: INITIAL_TILE_POSITION,
        type: selectedTiletype,
        locked: false,
      };
      setTileType("");
      dispatch(addTile(newTile));
    }
  };

  function isAddingTileAllowed(selectedTiletype?: string) {
    const isInitialFieldFree =
      visibleTiles.filter((item) => item.tilePosition === INITIAL_TILE_POSITION)
        .length === 0;

    if (
      gameState === GameStates.ViewBoard ||
      gameState === GameStates.Finished
    ) {
      toast(
        "Das Spiel wurde bereits beendet. Wenn du ein neues Teil legen möchtest, musst du eine neue Partie beginnen.",
        { type: toast.TYPE.INFO, autoClose: 7000 }
      );
      return false;
    }
    if (!isInitialFieldFree) {
      toast(
        "Bevor du ein neues Teil ziehen kannst, musst du zuerst das alte Teil auf dem Spielfeld platzieren  ",
        { type: toast.TYPE.ERROR, autoClose: 7000 }
      );
      return false;
    }

    if (
      selectedTiletype != null &&
      !availableTileTypes.includes(selectedTiletype)
    ) {
      toast(
        "Leider kann das gesuchte Teil '" +
          selectedTiletype +
          "' nicht gefunden werden. Entweder du hast dich vertippt oder das Teil wurde schon verwendet",
        { type: toast.TYPE.ERROR, autoClose: 7000 }
      );
      return false;
    }

    return true;
  }

  const handleRandomTile = () => {
    const type = availableTileTypes[0];
    handleAddTile(type);
  };

  const handleResetGame = () => {
    dispatch(resetGame());
  };

  const handleTileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTileType(event.target?.value);
  };

  return (
    <div className={[props.className, "actionbar"].join(" ")}>
      <Button
        className='actionbar__menu'
        content='menu'
        onClick={() => {
          handleOpenMenu();
        }}
      />
      {gameState == GameStates.InProgress &&
      (gameOptions?.type === GameTypes.Random ||
        gameOptions?.type === GameTypes.MultiplayerHost ||
        gameOptions?.type === GameTypes.MultiplayerClient ||
        getCurrentGameBoardType() === GameBoardTypes.Desktop) ? (
        <Button
          className='actionbar__random'
          content='random'
          onClick={() => {
            handleRandomTile();
          }}
        />
      ) : (
        ""
      )}
      {gameState == GameStates.InProgress &&
      (gameOptions?.type === GameTypes.Select ||
        getCurrentGameBoardType() === GameBoardTypes.Desktop) ? (
        <SearchButton
          className='actionbar__select'
          value={tileType}
          onChange={handleTileTypeChange}
          onClick={() => {
            handleAddTile();
          }}
        />
      ) : (
        ""
      )}
      {gameState == GameStates.InProgress &&
      gameOptions?.type === GameTypes.Select ? (
        <Button
          className='actionbar__search'
          content='search'
          onClick={() => {
            if (isAddingTileAllowed()) {
              dispatch(setModal(ModalTypes.Search));
            }
          }}
        />
      ) : (
        ""
      )}

      {gameState == GameStates.InProgress &&
      gameOptions?.type === GameTypes.Select ? (
        <Button
          className='actionbar__search'
          content='search'
          onClick={() => {
            if (isAddingTileAllowed()) {
              dispatch(setModal(ModalTypes.Search));
            }
          }}
        />
      ) : (
        ""
      )}

      {gameState === GameStates.Finished ||
      gameState === GameStates.ViewBoard ? (
        <NavLink to='/result' className='actionbar__points'>
          <Button
            className='actionbar__points'
            content='points'
            onClick={() => {}}
          />
        </NavLink>
      ) : (
        ""
      )}

      <Button
        className='actionbar__reset'
        content='reset'
        onClick={() => {
          handleResetGame();
        }}
      />
      <ColorButton
        className='actionbar__theme'
        onChange={(color: Theme) => {
          dispatch(setTheme(color));
        }}
      />
    </div>
  );
};

export default ActionBar;
