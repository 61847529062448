import "./index.css";
import * as React from "react";

export interface ButtonProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
}

export const InputButton: React.FunctionComponent<ButtonProps> = (props) => {
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      props.onClick();
    }
  };
  return (
    <div className="inputbutton">
      <input
        type="text"
        className="inputbutton__input"
        name=""
        id=""
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        maxLength={3}
        value={props.value}
      />
      <button
        onClick={() => {
          props.onClick();
        }}
        className="inputbutton__button"
      ></button>
    </div>
  );
};

export default InputButton;
