import "./index.css";
import * as React from "react";

export interface ButtonProps {
  content: string;
  onClick: () => void;
  className?: string;
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={[props.className, "button"].join(" ")}
      style={{
        backgroundImage: 'url("/static/buttons/' + props.content + '.svg")',
      }}
    ></button>
  );
};

export default Button;
