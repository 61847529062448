import "./index.css";
import * as React from "react";

export interface TileSVGProps {
  type: string;
  fillColor?: string;
  borderColor?: string;
  shadow?: string;
  className?: string;
  onClick?: () => void;
}

export const TileSVG: React.FunctionComponent<TileSVGProps> = (props) => {
  const tileBackground = props.fillColor || "#9D9696";
  const tileBorder = props.borderColor || "#333333";
  const visibilityArray = tileTypeToBooleanArray(props.type);
  let shadowArray = Array(10).fill(false);
  if (props.shadow) {
    shadowArray = tileTypeToBooleanArray(props.shadow);
  }

  return (
    <div
      className={[props.className, "tilesvg"].join(" ")}
      onClick={props.onClick}
    >
      <svg
        className='tilesvg__abs'
        viewBox='0 0 204.6188 202.564'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {shadowArray[2] ? (
          <path
            d='m 140.8094,13.282 33.5,58 h -11.5 l -33.5,-58 z'
            style={{ fill: "#9d9696", stroke: "#9d9696", strokeWidth: 4 }}
          />
        ) : (
          ""
        )}
        {shadowArray[3] ? (
          <path
            d='m 174.3094,71.282 6.5,11 -40.5,69 h -11.5 l -6.5,-11 40,-69 z'
            style={{ fill: "#9d9696", stroke: "#9d9696", strokeWidth: 4 }}
          />
        ) : (
          ""
        )}
        {shadowArray[4] ? (
          <path
            d='m 120.3094,140.782 6.5,10.5 H 58.309401 l -6,-10.5 z'
            style={{ fill: "#9d9696", stroke: "#9d9696", strokeWidth: 4 }}
          />
        ) : (
          ""
        )}
      </svg>
      <svg
        className='tilesvg'
        viewBox='0 0 164.6188 142.564'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          visibility='visible'
          d='M 2.3094013,71.282 42.309401,2 H 122.3094 l 40,69.282 -40,69.282 H 42.309401 Z'
          fill={tileBackground}
          stroke={tileBorder}
          strokeWidth='4'
        />
        {visibilityArray[3] ? (
          <g className='tile__line3'>
            <path
              d='m 136.7444,110.806 -9.746,0.146 -4.746,-8.513 -7.246,-4.184 -7.246,-4.183 -9.745499,0.147 -4.7459,-8.514 -7.246,-4.1834 -7.2459,-4.1835 -9.746,0.1467 -4.746,-8.5136 -7.2459,-4.1834 -7.246,-4.1835 -9.7459,0.1467 -4.746,-8.5136 -7.2459,-4.1835 -7.246,-4.1834 9,-15.5885 115.934999,66.935 z'
              style={{ fill: "#ff5599" }}
            />
            <path
              d='m 118.8714,78.3612 c 2.106,0.3845 4.021,1.0747 5.746,2.0707 1.725,0.996 2.887,2.2485 3.484,3.7574 0.598,1.509 0.513,2.9286 -0.255,4.2588 -0.6,1.0392 -1.398,1.7977 -2.395,2.2755 -0.984,0.4571 -2.089,0.5954 -3.315,0.414 1.143,1.3804 1.803,2.6614 1.979,3.8444 0.188,1.162 -0.072,2.356 -0.78,3.582 -1.08,1.871 -2.701,2.999 -4.864,3.386 -2.13,0.377 -4.307,-0.076 -6.531,-1.36 -1.06,-0.612 -2.021,-1.347 -2.884,-2.205 -0.829,-0.867 -1.399,-1.584 -1.709,-2.151 l -0.465,-0.851 3.487,-3.016 c 0.091,0.274 0.22,0.626 0.388,1.055 0.168,0.43 0.562,1.115 1.183,2.055 0.654,0.932 1.428,1.656 2.322,2.172 0.893,0.516 1.811,0.727 2.752,0.633 0.953,-0.115 1.687,-0.62 2.203,-1.513 0.516,-0.894 0.548,-1.86 0.094,-2.898 -0.433,-1.026 -1.336,-1.935 -2.707,-2.7268 l -2.682,-1.548 2.308,-2.7002 1.714,0.99 c 1.143,0.66 2.187,0.9162 3.131,0.7686 0.945,-0.1476 1.651,-0.6267 2.119,-1.4373 0.468,-0.8106 0.558,-1.5902 0.269,-2.3386 -0.276,-0.7693 -0.799,-1.3759 -1.568,-1.8199 -0.748,-0.432 -1.605,-0.7469 -2.572,-0.9447 -0.934,-0.2066 -2.029,-0.3261 -3.285,-0.3585 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[4] ? (
          <g className='tile__line4'>
            <path
              d='m 134.3564,115.201 -3.626,-2.094 0.373,-9.022 -7.626,4.834 -3.627,-2.093 0.32,-9.054 -7.573,4.866 -3.626,-2.094 0.374,-9.022 -7.627,4.835 -3.626399,-2.094 0.4276,-8.9906 -7.6806,4.8032 -3.6265,-2.0938 0.4818,-8.9594 -7.7348,4.7719 -3.6264,-2.0937 0.5359,-8.9282 -7.7889,4.7407 -3.6265,-2.0938 0.59,-8.8969 -7.8429,4.7094 -3.6265,-2.0937 0.2111,-9.1157 -7.4641,4.9282 -3.6265,-2.0938 0.2653,-9.0844 -7.5182,4.8969 -3.6265,-2.0937 0.3194,-9.0532 -7.5724,4.8657 -3.6265,-2.0938 0.3736,-9.0219 7.5,-12.9904 116.046999,67 z'
              style={{ fill: "#5fbcd3" }}
            />
            <path
              d='m 110.5854,89.041 14.17,-7.4073 5.504,2.3464 -7.402,11.8139 3.065,1.603 -2.13,2.969 -2.458,-1.503 -2.711,5.128 -4.365,-2.52 3.246,-4.902 -8.717,-4.991 z m 13.143,-3.469 -9.583,5.1504 5.131,2.9206 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[8] ? (
          <g className='tile__line8'>
            <path
              d='m 136.8564,110.87 -17.006,-4.044 2.5,-4.331 -17.005,-4.044 2.5,-4.331 -17.006399,-4.0444 2.5,-4.3301 -17.0059,-4.0449 2.5,-4.3301 -17.006,-4.0449 2.5,-4.3301 -17.0059,-4.0449 2.5,-4.3301 -17.0059,-4.0449 2.5,-4.3301 -17.0059,-4.0449 2.5,-4.3301 9,-15.5885 116.046999,67 z'
              style={{ fill: "#faa111" }}
            />
            <path
              d='m 109.8464,92.48 c 0.636,-1.101 1.567,-1.8248 2.792,-2.1706 1.237,-0.3666 2.461,-0.519 3.672,-0.457 -1.311,-2.3367 -1.451,-4.3988 -0.419,-6.1863 1.044,-1.8083 2.546,-2.9224 4.507,-3.3423 1.961,-0.4199 3.824,-0.1199 5.591,0.9001 1.767,1.02 2.955,2.2741 3.565,3.7622 0.63,1.5002 0.579,2.8842 -0.153,4.1521 -0.948,1.642 -2.855,2.6888 -5.721,3.1408 0.912,1.302 1.498,2.541 1.761,3.718 0.284,1.189 0.089,2.366 -0.583,3.53 -1.008,1.745 -2.626,2.82 -4.854,3.224 -2.196,0.395 -4.281,0.023 -6.256,-1.117 -1.954,-1.128 -3.302,-2.585 -4.044,-4.372 -0.721,-1.774 -0.674,-3.368 0.142,-4.782 z m 10.861,3.652 c -0.505,-0.901 -1.098,-1.77 -1.779,-2.606 -0.669,-0.858 -1.117,-1.449 -1.345,-1.775 -1.867,0.114 -3.064,0.628 -3.592,1.543 -0.528,0.914 -0.591,1.862 -0.187,2.843 0.403,0.981 1.145,1.784 2.226,2.408 1.102,0.636 2.089,0.943 2.961,0.92 0.905,-0.032 1.544,-0.37 1.916,-1.014 0.384,-0.665 0.317,-1.438 -0.2,-2.319 z m -0.81,-11.4836 c -0.312,0.5404 -0.386,1.0521 -0.222,1.5351 0.165,0.483 0.299,0.8512 0.402,1.1047 0.115,0.2327 0.331,0.579 0.647,1.0389 0.565,0.7973 1.083,1.4843 1.554,2.0609 1.652,-0.6535 2.682,-1.3336 3.09,-2.0403 0.42,-0.7274 0.469,-1.461 0.148,-2.2007 -0.288,-0.7485 -0.858,-1.3687 -1.711,-1.8607 -0.831,-0.48 -1.615,-0.6829 -2.35,-0.6088 -0.715,0.0861 -1.234,0.4098 -1.558,0.9709 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[2] ? (
          <g className='tile__line2'>
            <path
              d='m 20.809401,98.902 c 0,0 1.4962,-6.638 4.7843,-8.5362 3.2881,-1.8984 6.4961,2.0232 9.7843,0.1246 3.2881,-1.8984 1.4961,-6.6373 4.7843,-8.5357 3.2881,-1.8984 6.4961,2.0229 9.7843,0.1245 3.2881,-1.8984 1.4961,-6.6373 4.7842,-8.5357 3.2882,-1.8984 6.4962,2.0229 9.7843,0.1245 3.2882,-1.8984 1.4962,-6.6373 4.7843,-8.5357 3.2882,-1.8984 6.4962,2.023 9.7843,0.1246 3.2881,-1.8984 1.4962,-6.6373 4.7843,-8.5357 3.2881,-1.8985 6.4962,2.0229 9.7843,0.1245 3.2881,-1.8984 1.4962,-6.6373 4.7843,-8.5357 3.287799,-1.8984 6.495799,2.0229 9.783799,0.1245 3.289,-1.8984 1.497,-6.6373 4.785,-8.5357 3.288,-1.8984 6.496,2.0229 9.784,0.1245 3.288,-1.8984 1.496,-6.6373 4.784,-8.5357 3.288,-1.8984 9.785,0.1246 9.785,0.1246 l 9,15.5884 L 29.809401,114.49 Z'
              style={{ fill: "#ffaacc" }}
            />
            <path
              d='m 44.099401,87.4353 c -0.672,-1.164 -1.7147,-1.3379 -3.128,-0.5219 -0.873,0.504 -1.7314,1.249 -2.5755,2.2351 -0.8352,0.9534 -1.4566,1.7971 -1.8641,2.5315 l -0.5981,1.052 -1.7323,-4.9446 c 0.5586,-0.9044 1.3135,-1.8529 2.2647,-2.8455 0.9391,-1.0133 1.8452,-1.772 2.7181,-2.276 1.7667,-1.02 3.4441,-1.3787 5.0321,-1.0762 1.5968,0.2697 2.8272,1.1528 3.6912,2.6493 1.164,2.0161 1.316,4.7274 0.4559,8.134 l -2.1847,8.744 9.4031,-6.178 1.864,4.453 -6.6468,3.463 -7.2317,4.467 -1.3912,-3.562 2.1912,-11.325 c 0.4574,-2.2315 0.3681,-3.8981 -0.2679,-4.9997 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[6] ? (
          <g className='tile__line6'>
            <path
              d='m 20.809401,98.673 4.7595,-8.5216 7.2596,-4.1913 9.7595,0.1388 7.2595,-4.1913 4.7596,-8.5214 7.2595,-4.1913 9.7595,0.1388 7.2596,-4.1913 4.7595,-8.5214 7.2595,-4.1913 9.759699,0.1389 7.259,-4.1913 4.76,-8.5215 7.259,-4.1913 9.76,0.1389 7.26,-4.1913 9,15.5884 -116.152999,67.0605 z'
              style={{ fill: "#ff2a2a" }}
            />
            <path
              d='m 41.870501,93.295 c 0.7127,-1.742 1.8173,-3.0448 3.3137,-3.9088 1.4965,-0.864 3.0676,-1.0228 4.7133,-0.4764 1.6664,0.5344 3.0336,1.7265 4.1016,3.5762 1.068,1.85 1.275,3.864 0.6208,6.043 -0.6541,2.179 -1.9892,3.851 -4.0053,5.015 -1.9954,1.152 -3.9798,1.411 -5.9533,0.777 -1.9735,-0.635 -3.5842,-2.033 -4.8322,-4.194 -1.248,-2.162 -1.8649,-4.646 -1.8506,-7.453 0.0351,-2.8197 0.6448,-6.2756 1.8293,-10.3681 l 5.3956,-0.6626 c -2.4731,4.8365 -3.5841,8.7203 -3.3329,11.6517 z m 3.796,0.094 c -1.3303,0.768 -2.3154,1.822 -2.9554,3.162 0.2224,0.481 0.4057,0.846 0.5497,1.096 0.768,1.33 1.7081,2.214 2.8204,2.653 1.1004,0.418 2.1286,0.351 3.0847,-0.201 0.9768,-0.564 1.5779,-1.299 1.8031,-2.205 0.2251,-0.906 0.0677,-1.827 -0.4723,-2.762 -0.552,-0.956 -1.2842,-1.601 -2.1966,-1.933 -0.9244,-0.353 -1.8023,-0.29 -2.6336,0.19 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[7] ? (
          <g className='tile__line7'>
            <path
              d='m 20.809401,98.612 7.253,-4.187 -2.4957,-4.333 14.5016,-8.3725 2.5043,4.3276 14.5016,-8.3725 -2.4957,-4.3326 14.5016,-8.3725 2.5044,4.3276 14.5016,-8.3725 -2.4957,-4.3326 14.5016,-8.3725 2.504399,4.3276 14.502,-8.3725 -2.496,-4.3326 14.501,-8.3725 2.505,4.3276 7.248,-4.185 9,15.5885 -116.046999,67.0004 z'
              style={{ fill: "#add01e" }}
            />
            <path
              d='m 43.912101,105.543 -1.0666,-19.9195 -8.9702,6.1355 -1.7981,-4.6989 6.7763,-3.455 6.0194,-3.9741 1.4583,3.6059 0.9794,13.3601 1.8387,7.793 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[1] ? (
          <g className='tile__line1'>
            <path
              d='m 89.309401,138.282 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 5,-8.375 -5,-8.375 h -18 v 134 z'
              style={{ fill: "#bdbdbd" }}
            />
            <path
              d='m 81.569401,15.882 -0.54,14.04 0.432,9.36 h -5.04 l 0.432,-8.748 -0.288,-8.964 -5.04,0.036 v -2.34 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[5] ? (
          <g className='tile__line5'>
            <path
              d='m 70.309401,4.282 h 18 l 6,16.75 -6,16.75 6,16.75 -6,16.75 6,16.75 -6,16.75 6,16.75 -6,16.75 h -18 z'
              style={{ fill: "#00aa88" }}
            />
            <path
              d='m 86.761401,31.578 c 0,2.232 -0.9,4.14 -2.7,5.724 -1.776,1.584 -3.948,2.376 -6.516,2.376 -1.2,0 -2.352,-0.144 -3.456,-0.432 -1.104,-0.312 -1.908,-0.612 -2.412,-0.9 l -0.756,-0.468 1.512,-4.212 c 0.744,0.576 1.668,1.104 2.772,1.584 1.128,0.48 2.172,0.72 3.132,0.72 0.984,0 1.86,-0.3 2.628,-0.9 0.768,-0.624 1.152,-1.38 1.152,-2.268 0,-2.184 -1.464,-3.516 -4.392,-3.996 l -4.788,-0.792 V 16.098 l 6.192,0.432 7.308,-0.396 -0.792,4.968 -9.252,-0.828 v 4.212 l 3.924,0.684 c 2.016,0.36 3.588,1.116 4.716,2.268 1.152,1.152 1.728,2.532 1.728,4.14 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
        {visibilityArray[9] ? (
          <g className='tile__line9'>
            <path
              d='m 70.309401,4.282 h 18 c 0,0 6,9.8017 6,16.75 0,6.9483 -6,9.8017 -6,16.75 0,6.9483 6,9.8017 6,16.75 0,6.9483 -6,9.8017 -6,16.75 0,6.9483 6,9.8017 6,16.75 0,6.948 -6,9.802 -6,16.75 0,6.948 6,9.802 6,16.75 0,6.948 -6,16.75 -6,16.75 h -18 z'
              style={{ fill: "#ffd336" }}
            />
            <path
              d='m 81.937401,29.13 c -1.44,1.152 -3.036,1.728 -4.788,1.728 -1.752,0 -3.216,-0.648 -4.392,-1.944 -1.152,-1.296 -1.728,-3.012 -1.728,-5.148 0,-2.136 0.828,-3.984 2.484,-5.544 1.656,-1.56 3.636,-2.34 5.94,-2.34 2.328,0 4.188,0.768 5.58,2.304 1.392,1.536 2.088,3.552 2.088,6.048 0,2.496 -0.72,4.956 -2.16,7.38 -1.416,2.424 -3.66,5.112 -6.732,8.064 l -5.004,-2.124 c 4.536,-2.952 7.44,-5.76 8.712,-8.424 z m -3.24,-1.98 c 1.56,0 2.94,-0.42 4.14,-1.26 0.048,-0.528 0.072,-0.936 0.072,-1.224 0,-1.536 -0.372,-2.76 -1.116,-3.672 -0.744,-0.936 -1.68,-1.404 -2.808,-1.404 -1.104,0 -1.98,0.336 -2.628,1.008 -0.648,0.672 -0.972,1.56 -0.972,2.664 0,1.08 0.312,2.004 0.936,2.772 0.624,0.744 1.416,1.116 2.376,1.116 z'
              style={{ fill: "#333333", fillOpacity: 0.7 }}
            />
          </g>
        ) : (
          ""
        )}
      </svg>
    </div>
  );
};

export default TileSVG;

function tileTypeToBooleanArray(type: string) {
  let boolArr = Array(10).fill(false);
  const numberArr = type.split("").map((char) => {
    return parseInt(char);
  });
  boolArr = boolArr.map((value, index) => {
    return numberArr.includes(index);
  });
  return boolArr;
}
