import "./index.css";
import * as React from "react";
import Button from "../Button/Button";
import { Theme } from "../../../store/user/userReducer";

export interface ButtonProps {
  onChange: (color: Theme) => void;
  className?: string;
}

export const ColorButton: React.FunctionComponent<ButtonProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div
      className={[
        props.className,
        "colorbutton",
        expanded ? "expanded" : "",
      ].join(" ")}
    >
      <Button
        content="palette"
        onClick={() => {
          setExpanded(!expanded);
        }}
      />
      {Object.keys(Theme).map((color) => {
        return (
          <button
            key={color}
            className={["colorTile", color].join(" ")}
            style={{
              backgroundImage:
                'url("/static/buttons/color/' + color.toLowerCase() + '.svg")',
            }}
            onClick={() => {
              props.onChange((Theme as any)[color]);
            }}
          ></button>
        );
      })}
    </div>
  );
};

export default ColorButton;
