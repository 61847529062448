export const INITIAL_TILE_POSITION = 0;

export const pointCalculationArray = [
  [
    [1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11, 12],
    [13, 14, 15, 16],
    [17, 18, 19],
  ],
  [
    [1, 4, 8],
    [2, 5, 9, 13],
    [3, 6, 10, 14, 17],
    [7, 11, 15, 18],
    [12, 16, 19],
  ],
  [
    [8, 13, 17],
    [4, 9, 14, 18],
    [1, 5, 10, 15, 19],
    [2, 6, 11, 16],
    [3, 7, 12],
  ],
];

export const validPositionsDesktop = [
  { x: -2.5, y: 3.464 },
  { x: 0, y: 1.732 },
  { x: 0, y: 3.464 },
  { x: 0, y: 5.196 },
  { x: 1.5, y: 0.866 },
  { x: 1.5, y: 2.598 },
  { x: 1.5, y: 4.33 },
  { x: 1.5, y: 6.062 },
  { x: 3, y: 0 },
  { x: 3, y: 1.732 },
  { x: 3, y: 3.464 },
  { x: 3, y: 5.196 },
  { x: 3, y: 6.928 },
  { x: 4.5, y: 0.866 },
  { x: 4.5, y: 2.598 },
  { x: 4.5, y: 4.33 },
  { x: 4.5, y: 6.062 },
  { x: 6, y: 1.732 },
  { x: 6, y: 3.464 },
  { x: 6, y: 5.196 },
];
export const validPositionsMobile = [
  { x: 3, y: 10.5 },
  { x: 0, y: 1.732 },
  { x: 0, y: 3.464 },
  { x: 0, y: 5.196 },
  { x: 1.5, y: 0.866 },
  { x: 1.5, y: 2.598 },
  { x: 1.5, y: 4.33 },
  { x: 1.5, y: 6.062 },
  { x: 3, y: 0 },
  { x: 3, y: 1.732 },
  { x: 3, y: 3.464 },
  { x: 3, y: 5.196 },
  { x: 3, y: 6.928 },
  { x: 4.5, y: 0.866 },
  { x: 4.5, y: 2.598 },
  { x: 4.5, y: 4.33 },
  { x: 4.5, y: 6.062 },
  { x: 6, y: 1.732 },
  { x: 6, y: 3.464 },
  { x: 6, y: 5.196 },
];

export const validPositions__Shadows = [
  "234",
  "",
  "",
  "4",
  "",
  "",
  "",
  "4",
  "2",
  "",
  "",
  "",
  "34",
  "2",
  "",
  "",
  "34",
  "23",
  "23",
  "234",
];

export const availableTileTypes = [
  "123",
  "124",
  "128",
  "163",
  "164",
  "168",
  "173",
  "174",
  "178",
  "523",
  "524",
  "528",
  "563",
  "564",
  "568",
  "573",
  "574",
  "578",
  "923",
  "924",
  "928",
  "963",
  "964",
  "968",
  "973",
  "974",
  "978",
];

export const emojis = [
  "1F43C",
  "1F42F",
  "1F984",
  "1F427",
  "1F423",
  "1F9A9",
  "1F36B",
  "1F354",
  "1F367",
  "1F349",
  "1F951",
  "E0C3",
  "1F48C",
  "1F3AE",
  "1F3DC",
  "1F680",
  "1F4A9",
  "1F696",
];
