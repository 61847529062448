import {
  ActionTypes,
  ADD_TILE,
  MOVE_TILE,
  SET_POSITION_PARAMETER,
  UPDATE_GAME_STATE,
  VIEW_GAMEBOARD_REDUCER,
  MOVE_TILE_TO_INDEX,
  UPDATE_VALID_POSITIONS,
  updateValidPositionsAction,
  SET_GAME_OPTIONS,
  setGameOptionsAction,
  updatePointsAction,
  UPDATE_POINTS,
  updateGameStateAction,
  RESET_GAME,
  SET_GAME_INFOS,
  setGameInfosAction,
  resetGameAction,
} from "./gameActions";
import {
  getValidPositions,
  calculatePositionParameter,
  snapIntoAvailableFields,
  getResult,
  generateRandomSeed,
  getOrderedTiles,
} from "../../helper/GameboardHelper";
import {
  TileDefinition,
  GameStates,
  ValidTilePosition,
  GameOptions,
  GameTypes,
  GameInfos,
} from "../../definitions/GameboardInterfaces";

export interface gameStateType {
  availableTileTypes: Array<string>;
  visibleTiles: Array<TileDefinition>;
  validPositions: Array<ValidTilePosition>;
  gameState: GameStates;
  gameOptions: GameOptions | undefined;
  gameInfos: GameInfos | undefined;
  points: number;
}

const initialState: gameStateType = {
  visibleTiles: [],
  availableTileTypes: getOrderedTiles(
    0,
    new Date().toISOString().slice(0, 10).replace(/-/g, "")
  ),
  validPositions: [],
  gameState: GameStates.InProgress,
  gameOptions: {
    type: GameTypes.Random,
    selectionSeed: generateRandomSeed(),
    orderSeed: new Date().toISOString().slice(0, 10).replace(/-/g, ""),
  },
  gameInfos: {},
  points: 0,
};

export function gameReducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case VIEW_GAMEBOARD_REDUCER:
      return {
        ...state,
        visibleTiles: [
          ...state.visibleTiles.map((tile) => {
            return { ...tile, locked: true };
          }),
        ],
      };
    case RESET_GAME:
      const selectionSeed =
        action.payload.selectionSeed || generateRandomSeed();
      const orderSeed =
        action.payload.orderSeed ||
        new Date().toISOString().slice(0, 10).replace(/-/g, "");
      return {
        ...initialState,
        validPositions: state.validPositions.map((item) => {
          return { ...item, usedById: null };
        }),
        gameOptions: {
          ...action.payload,
          selectionSeed: selectionSeed,
          orderSeed: orderSeed,
        },
        gameInfos: {
          Start: new Date(),
        },
        availableTileTypes: getOrderedTiles(selectionSeed, orderSeed).filter(
          (tile) => tile !== null && tile !== undefined
        ),
      };
    case UPDATE_VALID_POSITIONS:
      return updateValidPositions(state, action);
    case UPDATE_POINTS:
      return updatePoints(state, action);
    case SET_GAME_OPTIONS:
      return setGameOptions(state, action);
    case SET_GAME_INFOS:
      return setGameInfos(state, action);
    case SET_POSITION_PARAMETER:
      return {
        ...state,
        validPositions: getValidPositions(calculatePositionParameter()),
      };
    case UPDATE_GAME_STATE:
      return updateGameState(state, action);
    case ADD_TILE:
      return {
        ...state,
        visibleTiles: [
          ...state.visibleTiles.map((tile) => {
            return { ...tile, locked: true };
          }),
          action.payload,
        ],
        availableTileTypes: state.availableTileTypes.filter(
          (tiletype, i) => !(tiletype === action.payload.type)
        ),
      };

    case MOVE_TILE_TO_INDEX:
      return {
        ...state,
        visibleTiles: state.visibleTiles.map((tile, i) => {
          if (tile.type === action.payload.tile.type && !tile.locked) {
            return { ...tile, tilePosition: action.payload.tilePosition };
          } else {
            return tile;
          }
        }),
      };

    case MOVE_TILE:
      const newPosition = snapIntoAvailableFields(
        action.payload.position,
        state.validPositions
      );
      return {
        ...state,
        visibleTiles: state.visibleTiles.map((tile, i) => {
          if (tile.type === action.payload.tile.type && !tile.locked) {
            return { ...tile, tilePosition: newPosition };
          } else {
            return tile;
          }
        }),
      };

    default:
      return state;
  }
}

function resetGame(state: gameStateType, action: resetGameAction) {
  const selectionSeed = action.payload.selectionSeed || generateRandomSeed();
  const orderSeed =
    action.payload.orderSeed ||
    new Date().toISOString().slice(0, 10).replace(/-/g, "");

  return {
    ...initialState,
    validPositions: state.validPositions.map((item) => {
      return { ...item, usedById: null };
    }),
    gameOptions: {
      ...action.payload,
      selectionSeed: selectionSeed,
      orderSeed: orderSeed,
    },
    gameInfos: {
      Start: new Date(),
    },
    availableTileTypes: getOrderedTiles(selectionSeed, orderSeed),
  };
}

function setGameOptions(
  state: gameStateType,
  action: setGameOptionsAction
): gameStateType {
  return {
    ...state,
    gameOptions: { ...action.payload },
  };
}

function setGameInfos(
  state: gameStateType,
  action: setGameInfosAction
): gameStateType {
  return {
    ...state,
    gameInfos: { ...state.gameInfos, ...action.payload },
  };
}

function updateValidPositions(
  state: gameStateType,
  action: updateValidPositionsAction
): gameStateType {
  const validPositions = state.validPositions.map((position, index) => {
    let tilesOnPosition = state.visibleTiles.filter(
      (tile) => tile.tilePosition === index && (tile.locked || !action.payload)
    );
    if (tilesOnPosition.length > 0) {
      return { ...position, usedById: tilesOnPosition[0].type };
    } else {
      return { ...position, usedById: null };
    }
  });
  return {
    ...state,
    validPositions: validPositions,
  };
}

function updateGameState(
  state: gameStateType,
  action: updateGameStateAction
): gameStateType {
  let newGameState = action.payload;
  if (newGameState === state.gameState) {
    return state;
  }
  return {
    ...state,
    gameState: newGameState,
  };
}

function updatePoints(
  state: gameStateType,
  action: updatePointsAction
): gameStateType {
  return {
    ...state,
    points: getResult(state.visibleTiles),
  };
}
