import * as React from "react";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import Tile from "./Tile";
import { TileDefinition } from "../../definitions/GameboardInterfaces";
import { useSelector } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { getCoordinates } from "../../helper/GameboardHelper";

function getStyles(
  left: number,
  top: number,
  isDragging: boolean
): React.CSSProperties {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: "20%",
    zIndex: 100,
  };
}

export interface DraggableTileProps {
  id: string;
  definition: TileDefinition;
}

const DraggableTile: React.FC<DraggableTileProps> = (props) => {
  const validPositions = useSelector(
    (state: RootStateType) => state.game.validPositions
  );
  const { id, definition } = props;
  const coordinates = getCoordinates(definition, validPositions);
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: "tile",
      id,
      definition: definition,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // canDrag: true,
  });

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div ref={drag} style={getStyles(coordinates.x, coordinates.y, isDragging)}>
      <Tile definition={definition} />
    </div>
  );
};

export default DraggableTile;
