import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../../store/reducer";
import {
  BackgroundColors,
  ModalTypes,
} from "../../../definitions/UserConstants";
import { Theme } from "../../../store/user/userReducer";
import { setModal } from "../../../store/user/userActions";
// import ReactModal = require("react-modal");
import EmojiInput from "../../EmojiInput/EmojiInput";
import Button from "../../Buttons/Button/Button";
import ActionButton from "../../Buttons/ActionButton/ActionButton";
import { resetGame } from "../../../store/game/gameActions";
import { convertEmojiIndicesToNumber } from "../../../helper/GameboardHelper";
import { GameTypes } from "../../../definitions/GameboardInterfaces";
import Modal from "react-modal";

export interface MultiplayerClientModalProps {
  className?: string;
}

export const MultiplayerClientModal: React.FunctionComponent<MultiplayerClientModalProps> = (
  props
) => {
  const currentModal = useSelector(
    (state: RootStateType) => state.user.currentModal
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function closeModal() {
    dispatch(setModal());
  }

  function handleEmojisChange(value: Array<number>) {
    if (value.length === 6) {
      dispatch(
        resetGame({
          type: GameTypes.Random,
          selectionSeed: convertEmojiIndicesToNumber(value),
        })
      );
      dispatch(setModal());
    }
  }

  return (
    <Modal
      isOpen={currentModal === ModalTypes.MultiplayerClient}
      onRequestClose={closeModal}
      className="multiplayerclientmodal"
      overlayClassName="multiplayerclientmodal__overlay"
      style={{
        content:
          //@ts-ignore
          { backgroundColor: BackgroundColors[Theme[theme]] },
      }}
    >
      <img
        className="multiplayerclientmodal__logo"
        src="/static/buttons/multiplayerclient.svg"
        alt="multiplayer logo"
      />
      <div className="multiplayerclientmodal__text">
        Gib hier die Emoji-Kette deines Freundes ein...
      </div>
      <EmojiInput onChange={handleEmojisChange} readOnly={false} />
    </Modal>
  );
};

export default MultiplayerClientModal;
