import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../../store/reducer";
import {
  BackgroundColors,
  ModalTypes,
} from "../../../definitions/UserConstants";
import { Theme } from "../../../store/user/userReducer";
import { setModal, setTheme } from "../../../store/user/userActions";
import { resetGame } from "../../../store/game/gameActions";
import MenuButton from "../../Buttons/MenuButton/MenuButton";
import Seperator from "../../Seperator/Seperator";
import { GameTypes } from "../../../definitions/GameboardInterfaces";
import ReactModal from "react-modal";

export interface NewGameModalProps {
  className?: string;
}

export const NewGameModal: React.FunctionComponent<NewGameModalProps> = (
  props
) => {
  const currentModal = useSelector(
    (state: RootStateType) => state.user.currentModal
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  const dispatch = useDispatch();

  ReactModal.setAppElement("#root");

  function closeModal() {
    dispatch(setModal());
  }

  return (
    <ReactModal
      isOpen={currentModal === ModalTypes.NewGame}
      onRequestClose={closeModal}
      className="newgamemodal"
      overlayClassName="newgamemodal__overlay"
      style={{
        content:
          //@ts-ignore
          { backgroundColor: BackgroundColors[Theme[theme]] },
      }}
    >
      <MenuButton
        icon="random"
        onClick={() => {
          dispatch(resetGame({ type: GameTypes.Random }));
          dispatch(setModal());
        }}
      >
        Normales Spiel
      </MenuButton>
      <Seperator color="turquoise" />
      <MenuButton
        icon="search"
        onClick={() => {
          dispatch(resetGame({ type: GameTypes.Select }));
          dispatch(setModal());
        }}
      >
        Freies Spiel'
      </MenuButton>
      <Seperator color="orange" />
      <MenuButton
        icon="multiplayerhost"
        onClick={() => {
          dispatch(resetGame({ type: GameTypes.MultiplayerHost }));
          dispatch(setModal(ModalTypes.MultiplayerHost));
        }}
      >
        Mehrspieler starten
      </MenuButton>
      <Seperator color="pink" />
      <MenuButton
        icon="multiplayerclient"
        onClick={() => {
          dispatch(resetGame({ type: GameTypes.MultiplayerClient }));
          dispatch(setModal(ModalTypes.MultiplayerClient));
        }}
      >
        Mehrspieler beitreten
      </MenuButton>
    </ReactModal>
  );
};

export default NewGameModal;
