import "./index.css";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { convertNumberToEmojiIndices } from "../../helper/GameboardHelper";
import { emojis } from "../../definitions/GameboardConstants";

export interface EmojiInputProps {
  onChange?: (value: Array<number>) => void;
  readOnly?: Boolean;
  className?: string;
}

export const EmojiInput: React.FunctionComponent<EmojiInputProps> = (props) => {
  const { className, onChange, readOnly } = props;
  const selectionSeed = useSelector(
    (state: RootStateType) => state.game.gameOptions?.selectionSeed
  );
  const [currentEmojis, setCurrentEmojis] = React.useState<Array<number>>([]);

  function renderEmojis(emojiIndices: Array<number>) {
    return emojiIndices.map((emojiIndex, index) => {
      return (
        <img
          key={"emoji_" + index}
          className="emoji"
          src={"/static/emoji/" + emojis[emojiIndex] + ".svg"}
        />
      );
    });
  }

  function renderEmojiSelection() {
    return (
      <div className="emojiInput__selection">
        {emojis.map((value, index) => {
          return (
            <img
              key={"emoji_" + index}
              className="emoji"
              src={"/static/emoji/" + value + ".svg"}
              onClick={() => {
                setCurrentEmojis([...currentEmojis, index]);
                if (onChange != null) {
                  onChange([...currentEmojis, index]);
                }
              }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={[
        className,
        "emojiInput",
        readOnly ? "emojiInput--readonly" : "",
      ].join(" ")}
    >
      <div className="emojiInput__display">
        {readOnly
          ? renderEmojis(convertNumberToEmojiIndices(selectionSeed || 0))
          : renderEmojis(currentEmojis)}
      </div>

      {!readOnly ? renderEmojiSelection() : ""}
    </div>
  );
};

export default EmojiInput;
