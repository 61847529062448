export const BackgroundColors__Transparent = {
  Yellow: "#BEA00050",
  Red: "#A8111150",
  Purple: "#93257450",
  Blue: "#4475BE50",
  Green: "#006A1E50",
  Grey: "#EEEBEB50",
};

export const BackgroundColors = {
  Yellow: "#EBE1AF",
  Red: "#E4B4B4",
  Purple: "#DDBBD3",
  Blue: "#C4D4EB",
  Green: "#AFD0B8",
  Grey: "#C9C4C4",
};

export const ForegroundColors = {
  Yellow: "#BEA000",
  Red: "#8E0000",
  Purple: "#932574",
  Blue: "#25356F",
  Green: "#006A57",
  Grey: "#827C7C",
};

export enum ModalTypes {
  None,
  Menu,
  Search,
  NewGame,
  MultiplayerHost,
  MultiplayerClient,
}
