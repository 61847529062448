import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../store/reducer";
import { Theme } from "../../store/user/userReducer";
import { TileDefinition } from "../../definitions/GameboardInterfaces";
import { getCoordinates } from "../../helper/GameboardHelper";
import TileSvg from "../Tile/TileSvg";
import { useDrop } from "react-dnd";
import { moveTileToIndex, moveTile } from "../../store/game/gameActions";
import {
  validPositions__Shadows,
  INITIAL_TILE_POSITION,
} from "../../definitions/GameboardConstants";
import { ForegroundColors } from "../../definitions/UserConstants";

export interface TileDropzoneProps {
  className?: string;
  style?: any;
  tilePosition: number;
}

export const TileDropzone: React.FunctionComponent<TileDropzoneProps> = (
  props
) => {
  const visibleTiles = useSelector(
    (state: RootStateType) => state.game.visibleTiles
  );
  const validPositions = useSelector(
    (state: RootStateType) => state.game.validPositions
  );
  const currentTheme = useSelector((state: RootStateType) => state.user.theme);
  const dispatch = useDispatch();

  const [, drop] = useDrop({
    accept: "tile",
    drop(
      tile: {
        id: string;
        type: string;
        definition: TileDefinition;
      },
      monitor
    ) {
      const delta = monitor.getDifferenceFromInitialOffset() as {
        x: number;
        y: number;
      };
      let tileCoordinates = getCoordinates(tile.definition, validPositions);
      let newCoordinates = {
        x: Math.round(tileCoordinates.x + delta.x),
        y: Math.round(tileCoordinates.y + delta.y),
      };

      const oldTile = visibleTiles.filter(
        (item) => item.type === tile.definition.type
      )[0];

      dispatch(moveTile({ ...oldTile }, newCoordinates));
    },
  });

  let svgProps = {
    className: "tiledropzone__svg",
    type: "",
    borderColor: "#9D9696",
    fillColor: "#EEEBEB",
    shadow: validPositions__Shadows[props.tilePosition],
  };

  if (props.tilePosition == INITIAL_TILE_POSITION) {
    //@ts-ignore
    svgProps.fillColor = ForegroundColors[Theme[currentTheme]];
    svgProps.borderColor = "#333333";
  }

  return (
    <div
      className={[props.className, "tiledropzone"].join(" ")}
      style={props.style}
    >
      <div className='tiledropzone__ref' ref={drop}></div>
      <TileSvg {...svgProps} />
    </div>
  );
};

export default TileDropzone;
