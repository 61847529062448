import "./index.css";
import * as React from "react";

export interface ButtonProps {
  onClick: () => void;
  className?: string;
}

export const ActionButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { className, onClick } = props;
  return (
    <button className={[className, "actionbutton"].join(" ")} onClick={onClick}>
      {props.children}
    </button>
  );
};

export default ActionButton;
