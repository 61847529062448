import "./index.css";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStateType } from "../../../store/reducer";
import {
  BackgroundColors,
  ForegroundColors,
  ModalTypes,
} from "../../../definitions/UserConstants";
import { Theme } from "../../../store/user/userReducer";
import { setModal } from "../../../store/user/userActions";
import TileSVG from "../../Tile/TileSvg";
import SearchInput from "../../SearchInput/SearchInput";
import { addTile } from "../../../store/game/gameActions";
import Modal from "react-modal";

export interface SearchModalProps {
  className?: string;
}

export const SearchModal: React.FunctionComponent<SearchModalProps> = (
  props
) => {
  const currentModal = useSelector(
    (state: RootStateType) => state.user.currentModal
  );
  const theme = useSelector((state: RootStateType) => state.user.theme);
  const availableTileTypes = useSelector(
    (state: RootStateType) => state.game.availableTileTypes
  );
  const dispatch = useDispatch();
  const [tileType, setTileType] = React.useState("");

  React.useEffect(() => {
    if (tileType.length >= 3) {
      dispatch(
        addTile({
          type: tileType,
          locked: false,
          tilePosition: 0,
        })
      );
      closeModal();
    }
  }, [tileType]);

  Modal.setAppElement("#root");

  function closeModal() {
    dispatch(setModal());
  }

  function handleTileTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      availableTileTypes.filter((item) => item.startsWith(event.target?.value))
        .length === 0
    ) {
      return;
    }
    setTileType(event.target?.value);
  }

  return (
    <Modal
      isOpen={currentModal === ModalTypes.Search}
      onAfterOpen={() => {
        setTileType("");
      }}
      onRequestClose={closeModal}
      className="searchmodal"
      overlayClassName="searchmodal__overlay"
      style={{
        content:
          //@ts-ignore
          { backgroundColor: BackgroundColors[Theme[theme]] },
      }}
    >
      <SearchInput value={tileType} onChange={handleTileTypeChange} />
      {getPossibleNextValues(availableTileTypes, tileType).map(
        (newTileType, index) => {
          return (
            <TileSVG
              key={"tile" + index}
              className={[
                "tile" + index,
                !newTileType.available ? "tile__notavailable" : "",
              ].join(" ")}
              type={newTileType.tileType}
              // @ts-ignore
              fillColor={ForegroundColors[Theme[theme]]}
              onClick={() => {
                if (newTileType.available === true) {
                  setTileType(newTileType.tileType);
                }
              }}
            />
          );
        }
      )}
    </Modal>
  );
};

export default SearchModal;

function getPossibleNextValues(
  availableTileTypes: string[],
  currentTileType: string
): Array<possibleNextValue> {
  const nextValues = [["1", "5", "9"], ["2", "6", "7"], ["3", "4", "8"], []];

  const possibleNextValues = nextValues[currentTileType.length].map(
    (additionalChar) => {
      let possibleTile = currentTileType + additionalChar;
      console.log(availableTileTypes);
      return {
        tileType: possibleTile,
        available:
          availableTileTypes.filter((item) => item.startsWith(possibleTile))
            .length > 0,
      };
    }
  );

  return possibleNextValues;
}

interface possibleNextValue {
  tileType: string;
  available: boolean;
}
