import * as seedrandom from "seedrandom";
import {
  validPositionsDesktop,
  pointCalculationArray,
  validPositionsMobile,
  availableTileTypes,
  emojis,
  INITIAL_TILE_POSITION,
} from "../definitions/GameboardConstants";
import {
  TilePosition,
  TileDefinition,
  GameBoardTypes,
  ValidTilePosition,
  GameStates,
} from "../definitions/GameboardInterfaces";
import { gameStateType } from "../store/game/gameReducer";

export const getValidPositions = (parameter: {
  multiplier: TilePosition;
  offset: TilePosition;
}) => {
  let validPositions;
  let gameboardType = getCurrentGameBoardType();
  console.log(GameBoardTypes[gameboardType]);
  switch (gameboardType) {
    case GameBoardTypes.MobilePortrait:
      validPositions = validPositionsMobile;
      break;

    case GameBoardTypes.Desktop:
      validPositions = validPositionsDesktop;
      break;

    default:
      validPositions = validPositionsDesktop;
      break;
  }

  return validPositions.map((item) => {
    return {
      coordinates: {
        x: item.x * parameter.multiplier.x + parameter.offset.x,
        y: item.y * parameter.multiplier.y + parameter.offset.y,
      },
      usedById: null,
    };
  });
};

export const getResult = (tiles: Array<TileDefinition>) => {
  let points = 0;
  let occupiedPositions = tiles.map((tile) => tile.tilePosition);
  let freePositions = Array.from(Array(20).keys()).filter(
    (x) => !occupiedPositions.includes(x)
  );
  let calculationTiles = [...tiles];
  // TODO do testing
  while (freePositions.length > 0) {
    let position = freePositions.shift() || 0;
    calculationTiles.push({
      type: "000",
      tilePosition: position,
      locked: true,
    });
  }
  const orderedTiles = calculationTiles.sort(
    (a, b) => a.tilePosition - b.tilePosition
  );

  pointCalculationArray.forEach((diagonal, diagonalIndex) => {
    diagonal.forEach((row, rowIndex) => {
      const rowValues = row.map((tileIndex) => {
        return Number(orderedTiles[tileIndex].type[diagonalIndex]);
      });

      if (rowValues.every((v) => v === rowValues[0])) {
        points += rowValues.reduce((a, b) => a + b, 0);
      }
    });
  });
  return points;
};

export const getCoordinates = (
  tile: TileDefinition,
  validPositions: Array<ValidTilePosition>
): TilePosition => {
  return { ...validPositions[tile.tilePosition].coordinates };
};

export const calculatePositionParameter = () => {
  const gameboard = document.getElementById("gameboard")!;
  const elementHeight = gameboard.offsetHeight;
  // const elementWidth = gameboard.offsetWidth;
  const parameter = {
    multiplier: { x: elementHeight * 0.11, y: elementHeight * 0.11 },
    offset: { x: elementHeight * 0, y: elementHeight * 0 },
  };
  return parameter;
};

export function getCurrentGameBoardType() {
  if (window.innerWidth < 500 && window.innerWidth < window.innerHeight) {
    return GameBoardTypes.MobilePortrait;
  }
  if (window.innerWidth < 850 && window.innerWidth > window.innerHeight) {
    return GameBoardTypes.MobileLandscape;
  }
  return GameBoardTypes.Desktop;
}

export const snapIntoAvailableFields = (
  position: TilePosition,
  possiblePositions: Array<ValidTilePosition>
) => {
  const getDistance = (point: TilePosition) => {
    return (
      Math.pow(point.x - position.x, 2) + Math.pow(point.y - position.y, 2)
    );
  };

  var closest = possiblePositions.slice(1).reduce(
    (currentMin, newPosition, newIndex) => {
      if (
        newPosition.usedById === null &&
        getDistance(newPosition.coordinates) < currentMin.d
      ) {
        currentMin.indexField = newIndex + 1;
        currentMin.d = getDistance(newPosition.coordinates);
      }
      return currentMin;
    },
    { indexField: 0, d: getDistance(possiblePositions[0].coordinates) }
  ).indexField;

  return closest;
};

export function generateRandomSeed(min = 0, max = 2220074): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getOrderedTiles(
  selectionSeed: number,
  orderSeed: string,
  totalNumberOfTiles = 27,
  selectedNumberOfTiles = 19
) {
  let selectedTiles = getSelectedTiles(
    selectionSeed,
    totalNumberOfTiles,
    selectedNumberOfTiles
  );
  let notSelectedTiles = Array.from(Array(selectedNumberOfTiles).keys()).filter(
    (x) => !selectedTiles.includes(x)
  );

  let orderedTileIndex = [
    ...generateRandomTileOrder(orderSeed, selectedTiles),
    ...generateRandomTileOrder(orderSeed, notSelectedTiles),
  ];

  const orderedTiles = orderedTileIndex.map(
    (tileIndex) => availableTileTypes[tileIndex]
  );
  return orderedTiles;
}

export function generateRandomTileOrder(
  seed: string,
  availableTiles: Array<any>
) {
  const rng = seedrandom.alea(seed);
  let resultingOrder = [];
  while (availableTiles.length > 0) {
    let currentIndex = Math.floor(rng() * availableTiles.length);
    resultingOrder.push(availableTiles[currentIndex]);
    availableTiles.splice(currentIndex, 1);
  }
  return resultingOrder;
}

function binomial(n: number, k: number): number {
  if (typeof n !== "number" || typeof k !== "number") return NaN;
  var coeff = 1;
  for (var x = n - k + 1; x <= n; x++) coeff *= x;
  for (x = 1; x <= k; x++) coeff /= x;
  return coeff;
}

function getSelectedTiles(
  selectionSeed: number,
  totalNumberOfTiles = 27,
  selectedNumberOfTiles = 19
) {
  selectionSeed =
    selectionSeed % binomial(totalNumberOfTiles, selectedNumberOfTiles);

  // Implementation of Combinatorial number system
  let result = [];
  let currentN = selectionSeed;
  let currentK = selectedNumberOfTiles;

  for (
    let i = Math.max(selectionSeed, selectedNumberOfTiles);
    i >= 0 && currentK >= 0;
    i--
  ) {
    // muss gerundet werden, um Ungenauigkeiten, bei grossen Zahlen zu verhindern
    let binomialResult = Math.round(binomial(i, currentK));
    if (i < currentK) {
      binomialResult = 0;
    }
    if (binomialResult <= currentN) {
      currentN = currentN - binomialResult;
      currentK = currentK - 1;
      result.push(i);
    }
  }

  return result;
}

export function convertNumberToEmojiIndices(input: number): Array<number> {
  let result: Array<number> = [];
  for (let i = 0; i < 6; i++) {
    result.unshift(input % emojis.length);
    input = Math.floor(input / emojis.length);
  }
  return result;
}

export function convertEmojiIndicesToNumber(input: Array<number>) {
  let result: number = 0;
  input.forEach((item, index) => {
    result += Math.pow(emojis.length, input.length - 1 - index) * item;
  });
  return result;
}

export function getNewGameState(state: gameStateType) {
  if (
    state.visibleTiles.length >= state.validPositions.length - 1 &&
    state.visibleTiles.filter(
      (tile) => tile.tilePosition === INITIAL_TILE_POSITION
    ).length <= 0 &&
    state.gameState === GameStates.InProgress
  ) {
    return GameStates.Finished;
  }
  if (state.visibleTiles.length === 0) {
    return GameStates.InProgress;
  }
  return state.gameState;
}
