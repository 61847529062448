import * as React from "react";
import ActionBar from "../../components/ActionBar/ActionBar";
import Gameboard from "../../components/Gameboard/Gameboard";
import "./index.css";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { RootStateType } from "../../store/reducer";
import { motion } from "framer-motion";
import { Redirect } from "react-router-dom";
import { GameStates } from "../../definitions/GameboardInterfaces";

const pageTransition = {
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const GamePage = () => {
  const gameState = useSelector((state: RootStateType) => state.game.gameState);

  return (
    <motion.div
      className="game"
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      transition={{ duration: 1 }}
    >
      <img className={["game__logoimage"].join(" ")} src={"/static/Logo.svg"} />
      <div className="game__dndWrapper">
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <ActionBar className="game__actionbar" />
          <Gameboard className="game__gameboard" />
        </DndProvider>
      </div>
      {gameState == GameStates.Finished ? (
        <Redirect push to={{ pathname: "/result" }} />
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default GamePage;
